import {HeadNavigate} from "../components/HeadNavigate/HeadNavigate";
import styles from './DealRead.module.scss'

import mask from'./img/mask.jpg'
import enterDesc from './img/Group5.png'
import da_ting_jiao_yi from './img/icon-大厅交易.png'
import ding_xiang_jiao_yi from './img/icon-定向交易.png'
import cu from './img/cu.png'
import ai from './img/ai.png'
import iconT1 from './img/icon-卖家指引1.png'
import iconT2 from './img/icon-卖家指引2.png'
import iconT3 from './img/icon-卖家指引3.png'
import iconT4 from './img/icon-卖家指引4.png'
import iconT5 from './img/icon-卖家指引5.png'
import iconT6 from './img/icon-卖家指引6.png'

import iconF1 from './img/icon-买家指引1.png'
import iconF2 from './img/icon-买家指引2.png'
import iconF3 from './img/icon-买家指引3.png'
import iconF4 from './img/icon-买家指引4.png'
import iconF5 from './img/icon-买家指引5.png'
import Bottom from "../components/Bottom/Bottom";

function TabOneItem(props: { title: string, desc: string }) {
    const {title, desc} = props
    return (
        <div className={styles.tabOneItem}>
            <div className={styles.tabOneItemTitle}>{title}</div>
            <div className={styles.tabOneItemDesc}>{desc}</div>
        </div>
    )
}


function TabTwoItem(props: { icon: string, title: string, desc: string}) {
    const {icon, title, desc} = props

    return (
        <div className={styles.TabTwoItem}>
            <img src={icon} alt={title}/>
            <div style={{fontSize: '18px', color: '#4a4a4a', lineHeight: '24px', marginTop: '20px'}}>{title}</div>
            <div style={{fontSize: '13px', color: '#9b9b9b', lineHeight: '24px', marginTop: '35px'}}>{desc}</div>
        </div>
    )
}

function Question(props:{ask:string, answer:string, bottomLine?:boolean}) {
    const {ask,answer,bottomLine} = props

    const style = bottomLine? {borderBottom:'2px solid #EFEFEF'} : {}
    return (
        <div className={styles.question} style={style}>
            <div className={styles.ask}>{ask}</div>
            <div className={styles.answer}>{answer}</div>
        </div>
    )
}

function TabThreeItem(props: {icon:string, title:string, desc:string,  direction:'left'|'right' }) {
    const {icon, title, desc,direction} = props
    if(direction ==='right') {
        return (
            <div className={styles.tabThreeItem} style={{float:direction}}>
                <img src={icon} alt={title} className={styles.tabThreeItemImg}/>
                <div className={styles.tabThreeItemTxt}>
                    <div className={styles.tabThreeItemTitle}>{title}</div>
                    <div className={styles.tabThreeItemDesc}>{desc}</div>
                </div>
            </div>
        )
    }else {
        return (
            <div className={styles.tabThreeItem} style={{float:direction, textAlign:'right'}}>
                <div className={styles.tabThreeItemTxt}>
                    <div className={styles.tabThreeItemTitle}  style={{float:'right'}}>{title}</div>
                    <div className={styles.tabThreeItemDesc}  style={{float:'right'}}>{desc}</div>
                </div>
                <img src={icon} alt={title} className={styles.tabThreeItemImg}/>
            </div>
        )
    }
}

export default function DealRead() {
    return (
        <div className="app">
            <div className={styles.appHeader}>
                <img src={mask} alt="mask" className={styles.mask} />
                <HeadNavigate height="470px">
                    <div className={styles.headTitle}>
                        <div className={styles.titleOne}>交易必读</div>
                        <div className={styles.titleTwo}>在飞马大宗平台上交易，您需了解以下内容</div>
                    </div>
                </HeadNavigate>
            </div>
            
            
            <div className={styles.content}>
                <div className={styles.tabOne}>
                    <div className={styles.title}>交易必备条件</div>
                    <div className={styles.tabOneContent}>
                        <TabOneItem title="1.注册成为平台认证会员" desc="符合准入标准的企业法人认证成功后，将会收到用于身份识别的数字证书
（U盾），并使用U盾在平台上进行交易。"/>
                        <TabOneItem title="2.开通专用银商结算账户" desc="您需开通平安银行银商结算通业务，通过银商结算通与客户进行交易。"/>
                    </div>
                    <div style={{clear:'both'}}/>
                    <div style={{marginTop:'90px'}}>
                        <img src={enterDesc} alt="企业资质"/>
                    </div>
                </div>
                <div className={styles.tabTwo}>
                    <div className={styles.tabTwoContent}>
                        <div className={styles.tabTwoContentTitle}>交易模式</div>
                        <div style={{display:'flex', justifyContent:'space-between', width:'56%', marginLeft:'auto', marginRight:'auto'}}>
                            <TabTwoItem icon={da_ting_jiao_yi} title="大厅交易" desc="浮动价、一口价交易"/>
                            <TabTwoItem icon={ding_xiang_jiao_yi} title="定向交易" desc="定向交易"/>
                        </div>
                    </div>
                    <div className={styles.tabTwoContent}>
                        <div className={styles.tabTwoContentTitle}>品类</div>
                        <div style={{display:'flex', justifyContent:'space-between', width:'56%',marginLeft:'auto', marginRight:'auto'}}>
                            <img src={cu} alt="铜" />
                            <img src={ai} alt="铝" />
                        </div>
                        <div style={{fontSize:'13px', color:'#9b9b9b', lineHeight:'24px', marginTop: '30px', width:'235px',marginLeft:'auto', marginRight:'auto' }}>飞马大宗有色金属电商平台的交易品种为铜、铝等，具体交易品类以平台公告为准</div>
                    </div>
                </div>
                <div className={styles.tabThree}>
                    <div className={styles.tabThreeTitle}>卖方交易指引</div>
                    <div style={{marginTop:'76px'}}>
                        <TabThreeItem icon={iconT1} title="取得注册电子仓单" desc="您可通过将符合标准的货物在指定仓库入库、原始仓单在平台注册、平台买入三种方式获取。" direction="right" />
                        <TabThreeItem icon={iconT2} title="挂单卖出" desc="插入U盾并登录飞马大宗有色金属电商平台后，可以将您持有的注册电子仓单挂单卖出。" direction="left" />
                        <TabThreeItem icon={iconT3} title="交易确认书签署" desc="买方摘单成功，则双方合同实际成立，须确保U盾在线以完成签署。逾期未签可能承担违约责任。" direction="right" />
                        <TabThreeItem icon={iconT4} title="收款与转权" desc="收款：在约定时限内，买方须根据交易确认书付款。转权：收款后，平台根据交易确认书指令仓库转权。" direction="left" />
                        <TabThreeItem icon={iconT5} title="出金" desc="可将交易账户可出金余额，转入绑定的银行账户。" direction="right" />
                        <TabThreeItem icon={iconT6} title="开具发票" desc="您须根据买方开票资料，在约定时限内开具与订单金额等值的增值税专用发票，并邮寄原件。" direction="left" />
                    </div>
                    <div style={{clear:'both'}}/>
                </div>
                <div className={styles.tabThree}>
                    <div className={styles.tabThreeTitle}>买方交易指引</div>
                    <div style={{marginTop:'76px'}}>
                        <TabThreeItem icon={iconF1} title="入金" desc="您在买入时须将资金从指定银行账户转入会员交易账户，作为交易的货款、费用及保证金等。" direction="right" />
                        <TabThreeItem icon={iconF2} title="摘单" desc="插入U盾登录后，可通过交易大厅或接受定向交易摘单。摘单将冻结交易金额的10%作为保证金。" direction="left" />
                        <TabThreeItem icon={iconF3} title="货权转移" desc="支付全部货款后，平台将根据交易确认书，指令仓库转移货权，转权完成后您可查询相关仓单。" direction="right" />
                        <TabThreeItem icon={iconF4} title="收取发票" desc="交易完成后，卖方须根据交易确认书约定的时限向您开具增值税专用发票，并将发票原件寄出。" direction="left" />
                        <TabThreeItem icon={iconF5} title="提货" desc="提货须申请提货单，输入提货人和车辆信息，获取提货密码，并与仓库预约提货时间后再提货。" direction="right" />
                    </div>
                    <div style={{clear:'both'}}/>
                </div>
                <div id='test' className={styles.tabTwo}>
                    <div className={styles.tabTwoContent}>
                        <div className={styles.tabTwoContentTitle}>贸易户</div>
                        <div style={{fontSize:'20px', color:'#9b9b9b', lineHeight:'24px', marginTop: '100px', width:'235px',marginLeft:'auto', marginRight:'auto' }}>
                            <a
                                href="../../maoyi.html"
                                target='_blank'
                                rel="noreferrer"
                                >面向贸易户详细介绍
                            </a>
                        </div>
                    </div>
                    <div className={styles.tabTwoContent}>
                        <div className={styles.tabTwoContentTitle}>做市商</div>
                        <div style={{fontSize:'20px', color:'#9b9b9b', lineHeight:'24px', marginTop: '100px', width:'235px',marginLeft:'auto', marginRight:'auto' }}>
                            <a
                                href="../../zuoshi.html"
                                target='_blank'
                                rel="noreferrer"
                                >面向做市商详细介绍
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.tabThree}>
                    <div className={styles.tabThreeTitle}>常见问题</div>
                    <Question ask="什么情况下会导致违约？" bottomLine={true} answer="买方在摘单后未按时足额支付货款，买方摘单后卖方未及时签署交易确认书，或卖方货物出现异常时均会导致违约。" />
                    <Question ask="违约方需承担什么违约责任？" bottomLine={true} answer="交易违约后，违约方需按买卖双方签署的交易确认书中相关条款规定承担违约责任、支付违约金。由于非会员责任等异常情况导致的违约，请及时
登录会员中心并在约定的时限内进行违约申诉。" />
                    <Question ask="在交易过程中有无法协商的有关交货、付款、发票、仓储运输等争议怎么办？" answer="平台设有调节团，由客服、营运、财务、法律等专业人士构成，平台将依据双方签署的交易确认书、交易规则和有关法律法规，根据事实情况秉持
公正态度进行调解。" />
                </div>
            </div>
            <Bottom/>
        </div>
    )
}
