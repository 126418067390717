import styles from './AboutUs.module.scss'
import {HeadNavigate} from '../components/HeadNavigate/HeadNavigate'


import mask from './img/mask.jpg'
import tab2 from './img/视频截图.png'
import tab31 from './img/icon-联系我们.png'
// import tab32 from './img/icon-传真.png'
import wechat from './img/微信客服.png'
import tab33 from './img/icon-地址.png'
import tab34 from './img/icon-邮件.png'
import tab35 from './img/icon-微信.png'
import Bottom from '../components/Bottom/Bottom'

function TabThreeItem(props:{icon:string, text:string}) {
    const {icon, text} = props
    return (
        <div className={styles.tabThreeItem}>
            <img src={icon} alt={text} className={styles.tabThreeItemImg}/>
            <div className={styles.tabThreeItemText}>{text}</div>
        </div>
    )
}
export default function AboutUs() {
    return (
        <div className="app">
            <div className={styles.appHeader}>
                <img src={mask} alt="mask" className={styles.mask} />
                <HeadNavigate height="470px">
                    <div className={styles.headTitle}>
                        <div className={styles.titleOne}>关于我们</div>
                        <div className={styles.titleTwo}>飞马大宗 | 一站式有色金属电商平台</div>
                    </div>
                </HeadNavigate>
            </div>
            <div className={styles.content}>
                <div className={styles.tabOne}>
                    <div className={styles.title}>
                        正规合法的平台
                    </div>
                    <div className={styles.tabOneDesc}>飞马大宗有色金属电商平台由深圳飞马大宗科技有限公司投资和运营。深圳飞马大宗科技有限公司是经国家工商总局批准，由上海合冠供应链有限公司出资设立的全资子公司。</div>
                    <div className={styles.tabOneDesc}>飞马大宗致力于解决传统有色金属贸易中存在的安全与效率问题，依托拥有自主知识产权的智慧系统和智慧仓储，以科技手段保障资金货物安全、提升交易效率、颠覆传统的有色金属交易体验</div>
                </div>
                <div className={styles.tabTwo}>
                    <div className={styles.title}>企业愿景</div>
                    <div className={styles.tabTwoDesc}>共创有色新繁荣</div>
                    <div>
                        <img src={tab2} alt="tab2" className={styles.tabTwoImg}/>
                    </div>
                </div>
                <div className={styles.tabThree}>
                    <div className={styles.title}>联系我们</div>
                    <div className={styles.tabThreeContent}>
                        <TabThreeItem icon={tab31} text="服务热线：755-33356810" />
                        <TabThreeItem icon={tab33} text="公司地址：深圳市南山区粤海街道海珠社区文心五路11号汇通大厦11楼" />
                        {/* <TabThreeItem icon={tab32} text="微信客服： 755-33356388" /> */}
                    </div>
                    <div className={styles.tabThreeContent}>
                        {/* <TabThreeItem icon={tab33} text="微信客服：" /> */}
                        <div className={styles.tabThreeItem}>
                            <img src={tab35} alt="微信客服" className={styles.tabThreeItemImg}/>
                            <div className={styles.tabThreeItemText}>微信客服：</div>
                            <img src={wechat} alt="wechat" height="200" width="200"/>
                        </div>
                            {/* <img src={wechat} alt="wechat" height="200" width="200"/> */}
                        <TabThreeItem icon={tab34} text="公司邮箱：dazong@fmscm.com" />
                    </div>
                </div>
            </div>
            <Bottom />
        </div>
    )
}
