import React from 'react'
import mask from './img/Mask.png'
import lowCost from './img/icon-low-cost.png'
import safe from './img/icon-safe.png'
import effecient from './img/icon-effecient.png'
import styles from './App.module.scss';

import onlineDeal from './img/icon-onlinedeal.png'
import onlineMoney from './img/icon-online-money.png'
import warehouse from './img/icon-warehouse.png'
import transferRight from './img/icon-transfer-right.png'

import informationSafe from './img/icon-information-safe.png'
import warehouseSafe from './img/icon-warehose-safe.png'
import moneySafe from './img/icon-money-safe.png'
// import paper from './img/icon-paper.png'

import content2 from './img/content2.jpg'
import {HeadNavigate} from '../components/HeadNavigate/HeadNavigate'
import Bottom from "../components/Bottom/Bottom";

function HeadBottomItem(props: { icon: string, title: string, desc: string }) {
    const {icon, title, desc} = props
    return (
        <div className={styles.bottomItem}>
            <img src={icon} alt={title} className={styles.img}/>
            <div style={{display: "inline-block", width: "158px"}}>
                <div className={styles.title}>{title}</div>
                <div className={styles.desc}>{desc}</div>
            </div>
        </div>
    )
}

function ContentOneItem(props: { icon: string, title: string, desc: string }) {
    const {icon, title, desc} = props;
    return (
        <div className={styles.contentOneItem}>
            <img src={icon} alt={title} className={styles.contentOneImg}/>
            <div className={styles.contentOneItemTitle}>{title}</div>
            <div className={styles.contentOneItemDesc}>{desc}</div>
        </div>
    )
}


function ContentTwoItem(props: { icon: string, title: string, desc: string }) {
    const {icon, title, desc} = props;
    return (
        <div className={styles.contentTwoItem}>
            <div className={styles.contentTwoItemImgBorder}>
                <img src={icon} alt={title} className={styles.contentTwoItemImg}/>
            </div>
            <div className={styles.contentTwoItemTitle}>{title}</div>
            <div className={styles.contentTwoItemDesc}>{desc}</div>
        </div>
    )
}


function App() {
    return (
        <div className="app">
            <div className="appHeader">
                <img src={mask} alt="mask" className="mask"/>
                <HeadNavigate>
                    <div className={styles.headTitle}>
                        <div className={styles.titleOne}> 一站式有色金属电商平台</div>
                        <div className={styles.titleTwo}>共创有色新繁荣</div>
                    </div>
                </HeadNavigate>
                <div className={styles.headBottom}>
                    <div className="content" style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "space-around",
                        alignItems: "center",
                        height: "100px"
                    }}>
                        <HeadBottomItem icon={safe} title="安全" desc="银商结算通 · 智能仓库控货"/>
                        <HeadBottomItem icon={effecient} title="高效" desc="一键达成交易 · 加速钱货流转"/>
                        <HeadBottomItem icon={lowCost} title="低成本" desc="降低交易成本 · 拓展贸易渠道"/>
                    </div>
                </div>
            </div>
            <div className={styles.contentOne}>
                <div className={styles.contentOneTitle}>飞马大宗电商平台</div>
                <div className={styles.contentOneDesc}>集商流、物流、资金流、信息流于一体的B2B电商平台</div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "315px",
                    marginTop: "53px",
                    width: "1160px"
                }}>
                    <ContentOneItem icon={onlineDeal} title="线上交易" desc="线上挂单、摘单、点价、电子合同签署，三分钟完成交易全流程。"/>
                    <ContentOneItem icon={transferRight} title="实时结算转权" desc="货款实时到账，货权实时转移，一次结算，自动生成转权凭证。"/>
                    <ContentOneItem icon={warehouse} title="智慧仓储" desc="智仓硬件+自研仓储系统，全程跟踪货物状态，库存信息实时同步。"/>
                    <ContentOneItem icon={onlineMoney} title="在线融资" desc="提供订单融资等供应链金融服务，手续简单，放款快速，还款灵活。"/>
                </div>
            </div>
            <div className={styles.contentTwo}>
                <div className={styles.contentTwoImg}>
                    <img src={content2} alt="content2"/>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: "space-around",
                    alignItems: "flex-start",
                    height: "320px",
                    width: "1160px",
                    position: "relative"
                }}>
                    <ContentTwoItem icon={moneySafe} title="资金安全"
                                    desc="买家付款才能转权，货物转权后卖家才能出金。天威U盾双重身份认证，保证用户资金安全。"/>
                    <ContentTwoItem icon={warehouseSafe} title="货物安全"
                                    desc="货物存放于指定仓库并经权威仓单认证，仓储系统直接对接交易平台，实时同步仓单信息。保证金交易模式降低违约几率。"/>
                    <ContentTwoItem icon={informationSafe} title="信息安全"
                                    desc="专属U盾和个人身份识别电子签名技术，系统与数据均达到银行安全级别，交易过程中的资金流水与货权转移状态清晰可查。"/>
                </div>
            </div>
            <Bottom />
        </div>
    );
}

export default App;
