import styles from './SafeSecurity.module.scss'
import {HeadNavigate} from '../components/HeadNavigate/HeadNavigate'

import mask from'./img/mask.jpg'
import tab21 from './img/专业的资质审核.png'
import tab22 from './img/全面的评估审查.png'
import tab23 from './img/健全的评价体系.png'

import tab31 from './img/资金明细.png'
import tab32 from './img/安全高效.png'

import tab41 from './img/遵从市场真实交易价格.png'
import tab42 from './img/挂单信息公开透明.png'

import tab5_back from './img/tab_five_back.png'

import tab61 from './img/icon-KMV.png'
import tab62 from './img/icon-专业安全.png'
import tab63 from './img/icon-非法.png'

import tab71 from './img/icon-专属U盾.png'
import tab72 from './img/icon-加密.png'
import tab73 from './img/icon-web漏洞检测.png'

import Bottom from '../components/Bottom/Bottom'

function TabTwoItem(props: {icon:string, title:string, desc:string}) {
    const { icon, title, desc} = props
    return (
        <div className={styles.tabTwoItem}>
            <img src={icon} alt={title} className={styles.tabTwoItemImg}/>
            <div className={styles.tabTwoItemTitle}>{title}</div>
            <div className={styles.tabTwoItemDesc}>{desc}</div>
        </div>
    )
}
function TabThreeItem(props:{icon:string, desc:string })  {
    const {icon,desc} = props
    return (
        <div className={styles.tabThreeItem}>
            <img src={icon} alt={desc} className={styles.tabThreeItemImg}/>
            <div className={styles.tabThreeItemDesc}>{desc}</div>
        </div>
    )
}

function TabFourItem(props:{icon:string, title:string, desc:string}) {
    const {icon,title, desc} = props
    return (
        <div className={styles.tabFourItem}>
            <img src={icon} alt={title} className={styles.tabFourItemImg}/>
            <div className={styles.tabFourItemTitle}>{title}</div>
            <div className={styles.tabFourItemDesc}>{desc}</div>
        </div>
    )
}

function TabFiveItem(props:{title:string, desc:string,}) {
    const {title, desc} = props
    return (
        <div className={styles.tabFiveItem}>
            <div className={styles.tabFiveItemTitle}>{title}</div>
            <div className={styles.tabFiveItemDesc}>{desc}</div>
        </div>
    )
}

function TabSixItem(props:{icon:string, desc:string}) {
    const {icon,desc} = props
    return (
        <div className={styles.tabSixItem}>
            <img src={icon} alt={desc} className={styles.tabSixItemImg}/>
            <div className={styles.tabSixItemDesc}>{desc}</div>
        </div>
    )
}

function TabSevenItem(props:{icon:string, title:string, desc:string}) {
    const {icon,title, desc} = props
    return (
        <div className={styles.tabSevenItem}>
            <img src={icon} alt={title} className={styles.tabSevenItemImg}/>
            <div className={styles.tabSevenItemTxt}>
                <div className={styles.tabSevenItemTitle}>{title}</div>
                <div className={styles.tabSevenItemDesc}>{desc}</div>
            </div>
        </div>
    )
}


export default function SafeSecurity() {
    return (
        <div className="app">
            <div className={styles.appHeader}>
                <img src={mask} alt="mask" className={styles.mask} />
                <HeadNavigate height="470px">
                    <div className={styles.headTitle}>
                        <div className={styles.titleOne}>安全保障</div>
                        <div className={styles.titleTwo}>全面安全保障，为您的交易保驾护航</div>
                    </div>
                </HeadNavigate>
            </div>
            <div className={styles.content}>
                <div className={styles.tabOne}>
                    <div className={styles.title}>
                        正规合法的平台
                    </div>
                    <div className={styles.tabOneDesc}>飞马大宗获国家工商总局、商务部认可，<br/>
                        上海合冠供应链有限公司出资设立的全资子公司，具有雄厚的资金实力、成熟的运营团队。</div>
                </div>
                <div className={styles.tabTwo}>
                    <div className={styles.title}>严格的准入机制</div>
                    <div className={styles.tabContent}>
                        <TabTwoItem icon={tab21} title="专业的资质审核" desc="对客户的资料、资质审核，有专业人员上门核实，确保客户真实、合规、优质"/>
                        <TabTwoItem icon={tab22} title="全面的评估审查" desc="通过政府披露网站、相关行业黑名单进行排查，确保排除欺诈和违法企业"/>
                        <TabTwoItem icon={tab23} title="健全的评价体系" desc="基于客户交易行为评级，并进行公开确保扶持优质客户以及淘汰劣质客户"/>
                    </div>
                </div>
                <div className={styles.tabThree}>
                    <div className={styles.title}>安全的资金管理</div>
                    <div className={styles.tabContent}>
                        <TabThreeItem icon={tab31} desc="隔离平台、买方、卖方的资金，杜绝资金挪用，保证用户资产安全。"/>
                        <TabThreeItem icon={tab32} desc="买方须按已签署交易确认书，向卖方支付既定金额，付款后即可收货，真正做到安全高效。"/>
                    </div>
                </div>
                <div className={styles.tabFour}>
                    <div className={styles.title}>公开透明的价格</div>
                    <div className={styles.tabContent}>
                        <TabFourItem icon={tab41} title="遵从市场真实交易价格" desc="浮动价格同上期所数据实时同步" />
                        <TabFourItem icon={tab42} title="挂单信息公开透明" desc="货物价格清晰，即时买卖即时成交" />
                    </div>
                </div>
                <div className={styles.tabFive}>
                    <img src={tab5_back} alt="tab_back" className={styles.tabFiveBackImg}/>
                    <div className={styles.tabFiveContent}>
                        <TabFiveItem title="智能仓储" desc="自建+合作专业物流仓库，自有物流仓储系统研发团队，提供更安全、更专业、更高效服务" />
                        <TabFiveItem title="全程跟踪" desc="收货、提货、移位、盘点通过智能设备采集序列号确认，保证精准高效，提供全程追溯能力" />
                        <TabFiveItem title="实时同步" desc="库存信息实时同步到电商平台，随时掌控仓单信息。挂单卖出时，仓库锁定仓单，杜绝一货多卖" />
                    </div>
                </div>
                <div className={styles.tabSix}>
                    <div className={styles.title}>稳健的风险控制</div>
                    <div className={styles.tabContent}>
                        <TabSixItem icon={tab61} desc="采用世界先进的KMV风控模型建立银行级风控体系"/>
                        <TabSixItem icon={tab62} desc="实时监控交易每一步，保障信息、资金、货物绝对真实"/>
                        <TabSixItem icon={tab63} desc="定期稽核审查机制，杜绝非法、不实等交易的行为"/>
                    </div>
                </div>
                <div className={styles.tabSeven}>
                    <div className={styles.title}>银行级的安全技术</div>
                    <TabSevenItem icon={tab71} title="专属U盾技术" desc="通过个人身份识别的电子签名技术，杜绝交易造假，为交易保驾护航" />
                    <TabSevenItem icon={tab72} title="数据加密备份" desc="通过数据加密和双重备份，建立银企专线，保护客户数据隐私和安全" />
                    <TabSevenItem icon={tab73} title="杜绝系统漏洞" desc="通过顶级安全团队的安全测试，系统与数据均已经达到银行安全级别" />
                </div>
            </div>
            <Bottom />
        </div>
    )
}
