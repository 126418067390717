import styles from './HeadNavigate.module.scss'

import logo from '../../imgs/logo.png'
import { Link } from 'react-router-dom'
import { ReactElement } from "react";

export function HeadNavigate(props:{children:ReactElement,height?:string} ) {
    const {children, height} = props
    return (
        <div className={styles.headWrap} style={{height: height? height: '640px' }}>
            <div className="content">
                <div>
                    <img src={logo} alt="logo"/>
                    <div style={{display:"inline-block"}}>
                        <Link to="/">首页</Link>
                        <Link to="deal_read">交易必读</Link>
                        <Link to="safe_security">安全保障</Link>
                        <Link to="about_us">关于我们</Link>
                    </div>
                </div>
                { children }
            </div>
        </div>
    )
}
