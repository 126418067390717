import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom'
import App from './App/App'
import DealRead from './DealRead/DealRead'
import SafeSecurity from './SafeSecurity/SafeSecurity'
import AboutUs from './AboutUs/AboutUs'

export default function Routes() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <App/>
                </Route>
                <Route path="/deal_read"><DealRead/></Route>
                <Route path="/safe_security"><SafeSecurity/></Route>
                <Route path="/about_us"><AboutUs/></Route>
            </Switch>
        </Router>
    )
}
