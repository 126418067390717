import styles from './Bottom.module.scss'

import phone from './img/icon-phone.png'
import onlineService from './img/icon-online-service.png'
import service from './img/icon-service.png'
import feedback from './img/icon-feedback.png'

function BottomOneItem(props: { icon: string, title: string }) {
    const {icon, title} = props
    return (
        <div className={styles.bottomOneItem}><img src={icon} alt={title} className={styles.bottomOneItemImg}/> <div style={{display:"inline-block", marginLeft:'10px'}}>{title}</div></div>
    )
}

export default function Bottom() {
    return (
        <div className={styles.bottom}>
            <div className={styles.bottomWrap}>
                <div className={styles.bottomOne}>
                    <BottomOneItem icon={phone} title="全天候专业服务" />
                    <BottomOneItem icon={service} title="1对1大客户服务特权" />
                    <BottomOneItem icon={onlineService} title="多渠道服务支持" />
                    <BottomOneItem icon={feedback} title="意见反馈" />
                </div>
                <div className={styles.bottomTwo}>
                    <div className={styles.link}>
                        友情连接：<a href="https://www.chinania.org.cn/">中国有色金属工业协会</a><a href="http://www.cnmte.cn/">有色金属技术经济研究院</a><a href="https://www.cnmn.com.cn/">中国有色金属信息网</a><a href="http://cuoal.com/">铜铝在线</a><a href="http://rrlyw.cn.gongxuku.com/">全球铝业网</a>
                        <br/>
                    </div>
                    <div style={{borderBottom:"1px solid #9B9B9B", paddingBottom:'50px', fontSize:'13px'}}>
                        Copyright © 2021-现在 DAZONG.COM All Rights Reserved | 深圳飞马大宗科技有限公司 | <a href="https://beian.miit.gov.cn" style={{margin:0}}>粤ICP备2024188875号-1</a> | 增值电信业务经营许可 粤B2-20210779
                    </div>
                </div>
            </div>
        </div>
    )
}
